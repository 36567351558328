import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Article from "../components/article";
import Masonry from 'react-masonry-css';
import {getShareImage} from '../helpers/react/utils';

export default ({data, pageContext, location}) => {
    const {next, previous} = data;
    const {indexed, post, uris, categories, section, homeFeaturedPosts, relatedArticles, siteUrl} = pageContext;
    let relatedPosts = [];
    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1
    };
    const shareImage = getShareImage(post, indexed, siteUrl);

    if (relatedArticles.length) {
        relatedPosts = <>
            <h2 >Contenido relacionado</h2>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid masonry"
                columnClassName="masonry-grid_column"
            >
                {relatedArticles.map( (article, index) => {
                    /*if (index>0 && index%4===0) {
                        return [
                            <Article key={`list-ad-${article.id}`} node={article} indexed={indexed} uris={uris} related type="ad" />,
                            <Article key={`list-related-${index}-${article.id}`} node={article} indexed={indexed} uris={uris} related />
                        ];
                    }*/
                    return <Article key={`list-related-${index}-${article.id}`} node={article} indexed={indexed} uris={uris} related />;
                })}
            </Masonry>
        </>;
    }

    //console.log(indexed)
    //console.log(post)

    return (
        <Layout
            indexedImages={indexed}
            uris={uris} 
            section={section}
            categories={categories}
            location={location}
            homeFeaturedPosts={homeFeaturedPosts}
            title={post.title.value}
            description={post.description.value}
            shareImage={shareImage}
        >
            <div key={`post-${post.id}`} className="row post-page">
                <div className="top-banner">
                    <a href="http://www.infolinks.com/join-us?aid=3257254" target="_blank" rel="noopener noreferrer nofollow">
                        <img className="desktop-ad" src="https://resources.infolinks.com/static/referral-banners/728X90/Creative2-728X90.jpg" alt="ad" />
                    </a>
                    <a href="http://www.infolinks.com/join-us?aid=3257254" target="_blank" rel="noopener noreferrer nofollow">
                        <img className="mobile-ad" src="https://resources.infolinks.com/static/referral-banners/320X50/Creative2-320X50.jpg" alt="ad" />
                    </a>
                </div>
                <div className="wrapper">
                    <div className="item">
                        <Article node={post} indexed={indexed} uris={uris} type="page" />
                    </div>
                    <div className="right-banners">
                        <div className="right-banner">
                            <a href="http://www.infolinks.com/join-us?aid=3257254" target="_blank" rel="noopener noreferrer nofollow">
                                <img className="desktop-ad" src="https://resources.infolinks.com/static/referral-banners/160X600/Creative2-160X600.jpg" alt="ad" />
                            </a>
                        </div> 
                        <div className="right-banner">
                            <a href="http://www.infolinks.com/join-us?aid=3257254" target="_blank" rel="noopener noreferrer nofollow">
                                <img className="desktop-ad" src="https://resources.infolinks.com/static/referral-banners/300X250/Creative2-300X250.jpg" alt="ad" />
                            </a>
                        </div>                        
                    </div>
                </div>
                <div className="s-content__pagenav">
                    <div className="s-content__nav">
                        <div className="s-content__prev">
                            {previous ? (
                                <>
                                    <span>Anterior</span>
                                    <Link to={`/${previous.title.slug}`}>
                                        {previous.title.value}
                                    </Link>
                                </>
                            ) : "\u00a0\u00a0"}
                        </div>
                        <div className="s-content__next">
                            {next ? (
                                <>
                                    <span>Siguiente</span>
                                    <Link to={`/${next.title.slug}`}>
                                        {next.title.value}
                                    </Link>
                                </>
                            ) : "\u00a0\u00a0"}
                        </div>
                    </div>
                </div>
                {relatedPosts}
                <div className="bottom-banner">
                    <input type="hidden" name="IL_IN_ARTICLE" />
                    {/*<img className="desktop-ad" src="https://lh3.googleusercontent.com/Sh7SH08FZ6pVcq7dxVgc3FmuW0wLFM-0cvYPRYxDFeB4vZ0GLS5qibRWO-4KXxl_tKo=w728-h90" alt="ad" />
                    <img className="mobile-ad" src="https://upload.wikimedia.org/wikipedia/commons/2/24/Ad-MediumRectangle-300x250.jpg" alt="ad" />*/}
                </div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
    query PageQuery ($nextId: String!, $previousId: String!) {
        next: cockpitPosts(id: { eq: $nextId }) {
            id
            title {
                value
                slug
            }
        }
        previous: cockpitPosts(id: { eq: $previousId }) {
            id
            title {
                value
                slug
            }
        }
    }
`;